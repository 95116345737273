@use "styles/Helpers/" as *;

.layout {
  width: 100%;
  min-height: $spacing64;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;

  @include sm {
    min-height: $spacing48;
  }
}

.linksContainer {
  display: flex;
  align-items: center;
  padding: 0 $spacing24;
  width: 100%;
  justify-content: space-between;
  @include sm {
    padding: 0 $spacing16 0 $spacing8;
  }
}

.iconBtn {
  @include sm {
    width: $spacing40;
    height: $spacing40;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;

  @include sm {
    width: $spacing24;
    height: $spacing24;
  }
}

.linksWrapper {
  display: flex;
  align-items: center;
  gap: $spacing40;

  @include sm {
    display: none;
  }
}
