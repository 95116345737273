@use "styles/Helpers/" as *;

.layout {
  display: flex;
  flex-direction: column;
  width: 566px;
  min-width: 240px;
  margin: 48px;
  position: fixed;
  z-index: 4;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 80px 1px;
  border-radius: 2px;
  box-sizing: border-box;
  transition: transform 150ms ease-in-out;

  @include sm {
    width: calc(100% - 64px);
    margin: $spacing16;
  }
}

.hidden {
  transform: translateY(-200%);
}
