@use "styles/Helpers/" as *;

.footerLayout {
  width: 100%;
  border-top: 1px solid $grey200;
  padding: $spacing30 $spacing24;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  @include sm {
    padding: $spacing30 $spacing10;
  }
}

.Container {
  display: flex;
  gap: $spacing24;
  align-items: center;
}

.footerContainer {
  @extend .Container;
  @include sm {
    gap: $spacing16;
  }
}

.btnContainer {
  @extend .Container;
  @include sm {
    gap: 0;
  }
}

.iconBtn {
  align-items: center;
  display: flex;
  justify-content: center;

  @include md {
    width: $spacing30;
    height: $spacing30;
  }
}

.icon {
  vertical-align: middle;
  font-size: $fontSize20;

  @include md {
    font-size: $fontSize16;
  }
}

.logo {
  width: $spacing20;
  height: $spacing20;
  object-fit: cover;
  @include md {
    width: $spacing20;
    height: $spacing20;
  }
}
