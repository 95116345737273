@use "styles/Helpers/" as *;

.logo {
  transition: fill 0.3s ease;

  &:hover {
    rect {
      fill: $primaryOrange;
    }
  }

  @include md {
    width: $spacing24;
    height: $spacing24;

    &:hover {
      rect {
        fill: black;
      }
    }
  }
}
