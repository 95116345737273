@use "styles/Helpers" as *;

.img {
  max-height: 80vh;
  max-width: 800px;
  object-fit: contain;
  object-position: center;
  background-color: $skeleton;

  @include md {
    max-width: 100%;
    height: auto;
  }
}
