@use "styles/Helpers" as *;

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $spacing30;
}

.imgContainer {
  width: 100%;
  aspect-ratio: 1/1;
  display: block;
  object-fit: cover;
  background-color: $skeleton;
  min-height: 200px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing10;

  @include md {
    max-width: 410px;
  }
}
