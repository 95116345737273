@use "styles/Helpers/" as *;

.button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.wrapper {
  display: none;

  @include sm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    margin-left: auto;

    &:focus {
      outline: $darkGrey200;
    }
  }

  &Active {
    .content {
      transform: translate(3px);
    }
    .line {
      background: $darkGrey300;
    }
    .top {
      transform: rotate(45deg) translate(0.5px, 0);
      transition: 0.25s ease-in-out;
    }
    .middle {
      transform: scaleY(0);
      transition: 0.25s ease-in-out;
    }
    .bottom {
      transform: rotate(-45deg) translate(0, 0.5px);
      transition: 0.25s ease-in-out;
    }
  }
}

.content {
  width: $spacing24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  div {
    width: $spacing24;
    height: 1.9px;
    border-radius: 10px;
    position: relative;
    background-color: $darkGrey300;
    transform-origin: 1.9px;

    .top {
      transform-origin: 0% 0%;
      transition: transform 0.5s ease-in-out;
      transition: all 1s;
    }
    .middle {
      transition: transform 0.2s ease-in-out;
    }
    .bottom {
      transform-origin: 0% 100%;
      transition: transform 0.5s ease-in-out;
    }
  }
}
