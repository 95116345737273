$breakpoints: (
  "xs": 0,
  "sm": 576px,
  "md": 820px,
  "lg": 1100px,
  "xl": 1200px,
  "xxl": 1200px,
  "xxxl": 1540px,
);

@mixin xs {
  @media (max-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

@mixin sm {
  @media (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin md {
  @media (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media (max-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl {
  @media (max-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: map-get($breakpoints, "xxl")) {
    @content;
  }
}

@mixin xxxl {
  @media (max-width: map-get($breakpoints, "xxxl")) {
    @content;
  }
}
