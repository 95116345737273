@use "styles/Helpers" as *;

.layout {
  @include sm {
    padding-bottom: $spacing8;
  }
}

.description {
  width: 480px;

  @include xxl {
    width: 420px;
  }

  @include lg {
    width: 320px;
  }

  @include md {
    width: 100%;
  }
}
