@use "styles/Helpers/" as *;

.navBarFilterlayout {
  height: 100%;
  justify-content: space-between;
  padding-top: $spacing64;
  overflow: auto;

  @include sm {
    height: calc(100svh - 176px);
  }

  @include xxxl {
    padding-top: 0;
  }
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing10;

  &Color {
    @extend .categoryContainer;
    flex-direction: row;
  }
}

.filterGroupButton {
  display: flex;
  width: 100%;
}

.filterBtn {
  flex: 1;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid $darkGrey300;
  border-radius: 0;
  padding: $spacing10 $spacing16;
  text-transform: uppercase;

  &:hover {
    background-color: $darkGrey300;
    color: $white100;
  }

  &Apply {
    @extend .filterBtn;
    background-color: $darkGrey300;
    color: $white100;
    &:hover {
      background-color: transparent;
      color: $darkGrey300;
    }
  }
}

.categoryLayout {
  border-top: 1px solid $grey200;
  padding: $spacing10 0;
  display: flex;
  flex-direction: column;
  gap: $spacing10;
}
