@use "./breakpoints.scss" as *;

//COLORS
$white100: #ffffff;
$lightGrey100: #f9f9f9;
$grey100: #e5e5e7;
$grey200: #dddddf;
$darkGrey200: #a0a0a1;
$darkGrey300: #323233;
$darkGrey300Hover: #3e3e3f;
$primaryOrange: #c5501e;
$skeleton: #ebebeb;

//FONT SIZE

$fontSize12: 12px;
$fontSize14: 14px;
$fontSize16: 16px;
$fontSize20: 20px;
$fontSize24: 24px;
$fontSize28: 28px;
$fontSize32: 32px;
$fontSize40: 40px;
$fontSize48: 48px;
$fontSize64: 64px;

//FONT WEIGHT
$fontWeights400: 400;
$fontWeights500: 500;

//FONT LETTER SPACING
$fontLs64S: -0.64px;
$fontLs128S: -1.28px;

//FONT LINE HEIGHT
$fontLh130: 130%;
$fontLh140: 140%;
$fontLh150: 150%;

//SPACING
$spacing8: 8px;
$spacing10: 10px;
$spacing12: 12px;
$spacing14: 14px;
$spacing16: 16px;
$spacing20: 20px;
$spacing24: 24px;
$spacing30: 30px;
$spacing40: 40px;
$spacing48: 48px;
$spacing60: 60px;
$spacing64: 64px;
$spacing80: 80px;
$spacing100: 100px;
$spacing120: 120px;
$spacing160: 160px;
$spacing150: 150px;
$spacing180: 180px;
$spacing200: 200px;
$spacing240: 240px;
