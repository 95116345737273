@use "styles/Helpers/" as *;

.progressBar {
  width: 100%;
  span {
    display: block;
  }
}

.bar {
  background-color: $grey100;
}

.progress {
  animation: loader 8s ease infinite;
  background-color: $darkGrey300;
  color: $grey100;
  padding: 2px;
  width: 0;
}

@keyframes loader {
  0% {
    width: 0;
  }
  20% {
    width: 10%;
  }
  25% {
    width: 24%;
  }
  43% {
    width: 41%;
  }
  56% {
    width: 50%;
  }
  66% {
    width: 52%;
  }
  71% {
    width: 76%;
  }
  94% {
    width: 86%;
  }
  100% {
    width: 100%;
  }
}
