@use "styles/Helpers/" as *;

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing40;

  &Container {
    @extend .description;
    gap: $spacing16;
    padding-bottom: $spacing24;
  }
}
