@use "styles/Helpers" as *;

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.imgItem {
  width: 100%;
  min-height: 200px;
  display: block;
  object-fit: contain;
}
