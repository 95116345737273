@use "styles/Helpers/" as *;

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  position: relative;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: $lightGrey100;
}
