@use "styles/Helpers" as *;

.artContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  text-decoration: none;
  text-decoration: none;
  position: relative;
}

.artContainerWithBorders {
  @extend .artContainer;
  padding: 0 $spacing30 $spacing80 $spacing30;
  @include md {
    padding: 0 $spacing10 $spacing60 $spacing10;
  }
}

.artContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imgContainerArt {
  width: 100%;
  display: flex;
  justify-content: center;

  @include md {
    flex: none;
  }
}

.imgContainerArtFull {
  @extend .imgContainerArt;

  @include md {
    height: auto;
  }
}

.artImg {
  width: 100%;
  display: block;
  max-height: 50vh;
  max-width: 50vh;
  aspect-ratio: 1 / 1;
  align-self: center;
  background-color: $skeleton;
}

.artImgWithBorders {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  max-width: 50vh;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  transition: opacity 0.4s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  @include md {
    max-width: 100%;
  }
}

.artImgFull {
  max-height: 100%;
  max-width: 100%;

  @include xxxl {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.artImgSkeleton {
  background-color: $skeleton;
}
