@use "styles/Helpers" as *;

.layout {
  padding-left: $spacing120;
  position: relative;

  @include xl {
    padding-left: 0;
  }
}

.wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: $spacing24;
  min-width: 260px;

  @include md {
    position: inherit;
    top: auto;
    transform: none;
    min-width: 200px;
  }
}

.title {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;

  @include sm {
    gap: $spacing8;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  @include sm {
    width: 28px;
    height: 28px;
  }
}

.icon {
  @include sm {
    width: 20px;
    height: 20px;
  }
}

.fullHeight {
  @extend .layout;
}

.square {
  @extend .layout;
  display: flex;
  align-self: flex-start;
  width: 100%;
  aspect-ratio: 1 / 1;

  @include md {
    aspect-ratio: auto;
  }

  .wrapper {
    max-width: 410px;
  }

  .btn {
    position: absolute;
    bottom: 0;

    @include md {
      display: none;
    }
  }
}
