@use "styles/Helpers" as *;

.galleryContainer {
  width: 100%;
}

.galleryLayout {
  padding: $spacing200 $spacing24 $spacing80 $spacing24;
  display: grid;
  grid-auto-columns: 1fr;
  width: 100%;
}

.galleryLayout :nth-child(2n + 1) {
  grid-column: span 2;
  @include md {
    grid-column: span 1;
  }
}
.galleryLayout :nth-child(2n + 2) {
  grid-column: 2 / span 2;
  @include md {
    grid-column: 2 / span 1;
  }
  @include sm {
    grid-column: span 1;
  }
}
.galleryLayout :nth-child(4n + 1) {
  grid-column: span 1;
}
.galleryLayout :nth-child(4n + 4) {
  grid-column: span 1;
}

.art {
  object-fit: contain;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 0 $spacing30 $spacing80 $spacing30;

  @include md {
    padding: 0 $spacing10 $spacing60 $spacing10;
  }
}

.artImg {
  min-height: 80px;
  min-width: 80px;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  display: block;
  object-fit: contain;
  margin-bottom: $spacing10;
}
