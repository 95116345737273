@use "styles/Helpers" as *;

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100svh;
  max-height: 1100px;
  gap: $spacing60;

  @include md {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    height: auto;
    max-height: none;
    gap: $spacing40;
  }

  @include sm {
    height: auto;
    max-height: none;
  }
}
