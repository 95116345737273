@use "styles/Helpers/" as *;

.drawerLayout {
  width: 100%;
  background-color: $white100;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  top: $spacing48;
  height: calc(100svh - 108px);
  padding: 0 $spacing16 $spacing8 $spacing16;
  overflow: hidden;

  @include sm {
    height: calc(100svh - 80px);
  }
}

.gallery {
  height: calc(100svh - 200px);
  top: 104px;

  @include sm {
    top: 86px;
    height: calc(100svh - 118px);
  }
}
