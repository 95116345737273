@use "styles/Helpers/" as *;

.text {
  letter-spacing: 0;
  font-weight: $fontWeights400;
  line-height: $fontLh150;

  @include sm {
    font-size: $fontSize16;
  }
}
@mixin header {
  font-weight: $fontWeights500;
}

.mainHeader {
  @include header();
  line-height: $fontLh130;
  letter-spacing: $fontLs128S;
  font-size: $fontSize64;

  @include sm {
    font-size: $fontSize40;
  }
}

.subHeader {
  font-weight: $fontWeights400;
  letter-spacing: $fontLs64S;
  line-height: $fontLh150;
  display: flex;
  align-items: center;
  font-size: $fontSize28;

  @include sm {
    font-size: $fontSize20;
    line-height: $fontLh140;
    max-width: 257px;
  }
}

.subHeaderMedium {
  font-weight: $fontWeights500;
  letter-spacing: $fontLs64S;
  line-height: $fontLh140;
  display: flex;
  align-items: center;
  font-size: $fontSize28;

  @include sm {
    font-size: $fontSize20;
  }
}

.basicHeader {
  @include header();
  font-size: $fontSize48;
  line-height: $fontLh130;
  letter-spacing: $fontLs128S;

  @include sm {
    font-size: $fontSize32;
  }
}

.smallHeader {
  text-transform: uppercase;
  font-weight: $fontWeights500;
}

.capitalize {
  text-transform: capitalize;
}

.preWrap {
  white-space: pre-wrap;
}
