@use "styles/Helpers" as *;

.skeletonContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 $spacing30 $spacing80 $spacing30;

  @include md {
    padding: 0 $spacing10 $spacing60 $spacing10;
  }
}

.skeletonContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.skeletonContenerArt {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}

.skeletonArt {
  width: 100%;
  flex: 1;
  max-height: 50vh;
  max-width: 50vh;
  aspect-ratio: 1/1 !important;
  align-self: center;

  @include md {
    width: 35vw;
    height: auto;
  }
}

.skeletonBox {
  margin: $spacing24 auto;
  max-width: 300px;
  display: flex;
  justify-content: center;
}
