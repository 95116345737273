@use "styles/Helpers" as *;

.layout {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include md {
    gap: $spacing120;
  }
}