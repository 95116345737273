@use "styles/Helpers/" as *;

.btn,
.text,
.textLight,
.rect,
.arrow {
  display: flex;
  flex-wrap: nowrap;
  font-weight: $fontWeights500;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: $fontSize16;

  &:hover,
  &:active &:not([disabled]) {
    color: $primaryOrange;
  }
  &:disabled {
    color: $darkGrey200;
  }
  &:focus {
    outline: $darkGrey200;
  }
  &:global.active {
    font-weight: $fontWeights500;
  }
}

.text {
  background-color: transparent;
  letter-spacing: 0;
  border: none;
  text-align: center;
}

.textLight {
  font-weight: $fontWeights400;
}

.rect,
.rectDark {
  border: 1px solid $darkGrey300;
  border-radius: 0;
  background-color: transparent;
  padding: $spacing10 $spacing16;
  text-align: center;

  &Negative {
    @extend .rect;
    color: $darkGrey200;
    border: 1px solid transparent;

    &:hover,
    &:active {
      &:not([disabled]) {
        background-color: $darkGrey300;
        color: $white100;
      }
    }
    &:disabled {
      color: $darkGrey200;
      border: 1px solid $darkGrey200;
    }
    &:focus {
      outline: $darkGrey200;
    }
  }

  &:hover,
  &:active {
    &:not([disabled]) {
      background-color: $darkGrey300Hover;
      color: $white100;
    }
  }
  &:disabled {
    color: $darkGrey200;
    border: 1px solid $darkGrey200;
  }
  &:focus {
    outline: $darkGrey200;
  }
}

.rectDark {
  background-color: $darkGrey300;
  color: $white100;
  &:hover {
    background-color: $white100;
    color: $darkGrey300;
  }
}

.arrow {
  transform: rotate(0deg);
  margin-left: $spacing10;
  vertical-align: middle;
  font-size: $fontSize28;
  font-weight: $fontWeights500;
  letter-spacing: $fontLs64S;
  line-height: $fontLh140;
  align-items: center;

  .arrowText {
    border-bottom: 2px solid;
  }

  @include sm {
    font-size: $fontSize24;
  }

  &Rotate {
    @extend .arrow;
    transition: transform 0.5s ease-in-out 0s;
    transform: rotate(360deg);
  }
}

.mobileMenu {
  line-height: $fontLh150;
  @include sm {
    font-size: $fontSize28;
    font-weight: 400;
    line-height: $fontLh150;
  }
}

.mobileVis {
  @include sm {
    display: none;
  }
}

.fullWidth {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.noWrap {
  white-space: nowrap;
}

.upperCase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}
