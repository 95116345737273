@use "styles/Helpers" as *;

.layout {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  flex-direction: column;
  justify-content: center;

  @include sm {
    padding-top: $spacing160;
  }
}

.title {
  text-align: center;
  padding: $spacing80 0;
  text-align: center;
}

.subtitle {
  padding-bottom: $spacing40;
  display: flex;
  justify-content: center;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: $spacing20;
  text-align: center;
}

.icons {
  display: flex;
  justify-content: center;
  padding-top: $spacing30;
  gap: $spacing30;

  @include md {
    gap: $spacing8;
  }
}

.iconBtn {
  @include md {
    width: $spacing30;
    height: $spacing30;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon {
  font-size: $fontSize24;

  @include md {
    font-size: $fontSize16;
  }
}
