@use "styles/Helpers" as *;

* {
  color: inherit;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  min-height: 100svh;
  margin: 0;
  overflow-y: scroll;
  font-size: $fontSize16;
  color: $darkGrey300;
  line-height: $fontLh150;
}

// SIZES
.xs {
  font-size: $fontSize12;
}

.sm {
  font-size: $fontSize14;
  @include sm {
    font-size: $fontSize12;
  }
}

.s {
  font-size: $fontSize16;
  @include sm {
    font-size: $fontSize20;
  }
}

.m {
  font-size: $fontSize24;
  @include sm {
    font-size: $fontSize20;
  }
}

.md {
  font-size: $fontSize28;
  @include sm {
    font-size: $fontSize20;
  }
}

.lg {
  font-size: $fontSize48;
  @include sm {
    font-size: $fontSize32;
  }
}

.xl {
  font-size: $fontSize64;
  @include sm {
    font-size: $fontSize32;
  }
}

// COLORS
.primary {
  color: $darkGrey300;
}

.primaryOrange {
  color: $primaryOrange;
}
