@use "styles/Helpers" as *;

@mixin mobileLayout {
  @include md {
    flex-direction: column;
  }
}

.alternating {
  &:nth-of-type(even) {
    flex-direction: row-reverse;

    @include md {
      @include mobileLayout;
    }
  }
}

.reverse {
  flex-direction: row-reverse;

  @include xl {
    padding-top: $spacing160;
  }

  @include md {
    @include mobileLayout;
    padding-top: $spacing120;
  }
}

.mobileReverse {
  @include md {
    flex-direction: column-reverse;
  }
}

.wrapper {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;

  @include md {
    width: 100%;
    height: 100%;
  }
}

.btn {
  display: none;

  @include md {
    padding-top: $spacing40;
    display: block;
  }
}
