@use "styles/Helpers/" as *;

.toggleOverlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  z-index: 3;
  position: absolute;
  top: -48px;
  left: 283px;
  transform: translateX(-50%);

  @include sm {
    top: -16px;
    left: -32px;
    transform: translateX(0);
  }
}
