@use "styles/Helpers/" as *;

.messageLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: $spacing180;
}

.messageImg {
  max-height: 50vh;
  max-width: 80%;
  object-fit: contain;
}
