@use "styles/Helpers" as *;

.layout {
  width: 100%;
  display: flex;
  gap: $spacing40;
  padding: 0 $spacing120 $spacing100;

  @include xl {
    padding: 0;
  }

  @include md {
    flex-direction: column;
    padding: 0;
    gap: $spacing120;
  }
}
