@use "styles/Helpers" as *;

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  width: 100%;
  padding: $spacing240 $spacing60 $spacing120 $spacing60;

  @include md {
    padding-top: $spacing60;
  }

  @include sm {
    padding: $spacing200 $spacing24 $spacing60 $spacing24;
  }
}

.g120 {
  gap: $spacing120;
  @include md {
    gap: $spacing160;
  }
  @include sm {
    gap: $spacing160;
  }
}

.fullHeight {
  padding-bottom: 0;
  padding-top: 0;

  @include md {
    padding-top: $spacing60;
    padding-bottom: $spacing180;
  }

  @include md {
    padding-top: $spacing60;
    padding-bottom: $spacing150;
  }
}
